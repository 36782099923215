<template>
  <div class="relative">
    <Carousel
      ref="carousel"
      :elements="episodes"
      :elements-per-page="2.2"
      :elements-per-page-desktop="6.5"
      :end-padding="true"
      :end-padding-desktop="true"
      :centered="false"
      :gap="20"
      :skeleton-nb-elements="6"
      :controls="true"
      class="mb-5">
      <template #slide="{ element }: { element: any, active: boolean }">
        <ColumnCard
          :image="{
            src: element.image,
            alt: translateItem(element)?.title,
            provider: 'directus'
          }"
          :logo="{
            src: element?.radio?.logo,
            alt: element?.radio?.name,
            provider: 'directus'
          }"
          :category="element.category"
          :title="translateItem(element.program)?.title"
          :to="
            localePath({
              name: 'radio-program-episode',
              params: {
                radio: config.public.radioBoaSlug,
                program: element.program.id,
                episode: element.id
              }
            })
          "
          :description="translateItem(element)?.title"
          @play="$player.loadEpisode(element.id)" />
      </template>
    </Carousel>
  </div>
</template>

<script lang="ts" setup>
import { translateItem } from '@/composables/use-translations'

const localePath = useLocalePath()

defineProps<{
  episodes: Episode[]
}>()

const config = useRuntimeConfig()
</script>
